<template>
  <div>
    <ca-card class="mb-3">
      <template #header>
        <h4>Fahrzeughistorie</h4>
      </template>
      <template #body>
        <div class="form-row my-3 align-items-center">
          <div class="col-12 col-md-8">
            <basic-select
              id="typeOfCar"
              v-model="additionalData.typeOfCar"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Fahzeugart / Anzahl der Fahrzeughalter"
              name="Fahzeugart"
              placeholder="Bitte wählen"
              required
              :options="[
                {label: 'Gebrauchtfahrzeug', value: 'USED_CAR'},
                {label: 'Jahreswagen', value: 'YEAR_CAR'},
                {label: 'Neuwagen', value: 'NEW_CAR'}
              ]"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-select
              id="numberOfCarOwner"
              v-model="additionalData.numberOfCarOwner"
              :margin="false"
              placeholder="Bitte wählen"
              name="Anzahl der Fahrzeughalter"
              required
              :options="[
                {label: '1', value: '1'},
                {label: '2', value: '2'},
                {label: '3', value: '3'},
                {label: '4', value: '4'},
                {label: '5', value: '5'},
                {label: '6', value: '6'},
                {label: '7', value: '7'},
                {label: '8', value: '8'},
                {label: '9', value: '9'},
                {label: '10', value: '10'}
              ]"
            />
          </div>
        </div>
        <div class="form-row my-3 align-items-center">
          <div class="col-12 col-md-8">
            <basic-select
              id="damagedVehicle"
              v-model="additionalData.damagedVehicle"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Beschädigtes Fahrzeug / Unfallfahrzeug"
              name="Beschädigtes Fahrzeug"
              placeholder="Bitte wählen"
              required
              :options="[
                {label: 'Ja', value: 'true'},
                {label: 'Nein', value: 'false'}
              ]"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-select
              id="accidentVehicle"
              v-model="additionalData.accidentVehicle"
              :margin="false"
              placeholder="Bitte wählen"
              name="Unfallfahrzeug"
              required
              :options="[
                {label: 'Ja', value: 'true'},
                {label: 'Nein', value: 'false'}
              ]"
            />
          </div>
        </div>
        <basic-select
          id="roadworthy"
          v-model="additionalData.roadworthy"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Fahrtauglich"
          placeholder="Bitte wählen"
          required
          :options="[
            {label: 'Ja', value: 'true'},
            {label: 'Nein', value: 'false'}
          ]"
        />
        <basic-checkbox
          id="nonSmokingVehicle"
          v-model="additionalData.nonSmokingVehicle"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Nichtraucherfahrzeug"
        />
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Wartung und Inspektion</h4>
      </template>
      <template #body>
        <div class="form-row my-3 align-items-center">
          <div class="col-12 col-md-8">
            <basic-select
              id="HUDateOfExpireMonth"
              v-model="additionalData.HUDateOfExpireMonth"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="HU gültig bis"
              name="HU Monat"
              placeholder="Monat wählen"
              required
              :options="[
                {label: 'Januar', value: 1},
                {label: 'Februar', value: 2},
                {label: 'März', value: 3},
                {label: 'April', value: 4},
                {label: 'Mai', value: 5},
                {label: 'Juni', value: 6},
                {label: 'Juli', value: 7},
                {label: 'August', value: 8},
                {label: 'September', value: 9},
                {label: 'Oktober', value: 10},
                {label: 'November', value: 11},
                {label: 'Dezember', value: 12}
              ]"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-input
              id="HUDateOfExpireYear"
              v-model="additionalData.HUDateOfExpireYear"
              required
              :margin="false"
              name="HU Jahr"
              placeholder="Jahr wählen"
            />
          </div>
        </div>
        <basic-checkbox
          id="maintenanceGuide"
          v-model="additionalData.maintenanceGuide"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Scheckheftgepflegt"
        />
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Garantien</h4>
      </template>
      <template #body>
        <basic-checkbox
          id="manufacturerWarranty"
          v-model="additionalData.manufacturerWarranty"
          label="Herstellergarantie"
        />
        <basic-checkbox
          id="usedCarWarrantyPossible"
          v-model="additionalData.usedCarWarrantyPossible"
          label="Gebrauchtwagengarantie möglich"
        />
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Ausstattung</h4>
      </template>
      <template #body>
        <basic-select
          id="colorOutside"
          v-model="additionalData.colorOutside"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Außenfarbe"
          placeholder="Bitte wählen"
          required
          :options="[
            {label: 'Beige', value: 'BEIGE'},
            {label: 'Blau', value: 'BLUE'},
            {label: 'Braun', value: 'BROWN'},
            {label: 'Gelb', value: 'YELLOW'},
            {label: 'Gold', value: 'GOLD'},
            {label: 'Grau', value: 'GREY'},
            {label: 'Grün', value: 'GREEN'},
            {label: 'Orange', value: 'ORANGE'},
            {label: 'Rot', value: 'RED'},
            {label: 'Schwarz', value: 'BLACK'},
            {label: 'Silber', value: 'SILVER'},
            {label: 'Violett', value: 'PURPLE'},
            {label: 'Weiß', value: 'WHITE'}
          ]"
        />
        <basic-checkbox
          id="metallic"
          v-model="additionalData.metallic"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Metallic"
        />
        <div class="form-row my-3 align-items-center">
          <div class="col-12 col-md-8">
            <basic-select
              id="materialInside"
              v-model="additionalData.materialInside"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Material der Innenausstattung / Farbe der Innenausstattung"
              name="Material der Innenausstattung"
              placeholder="Bitte wählen"
              required
              :options="[
                {label: 'Vollleder', value: 'LEATHER'},
                {label: 'Teilleder', value: 'PARTIAL_LEATHER'},
                {label: 'Stoff', value: 'FABRIC'},
                {label: 'Velours', value: 'VELOUR'},
                {label: 'Alcantara', value: 'ALCANTARA'},
                {label: 'Andere', value: 'OTHER_INTERIOR_TYPE'},
              ]"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-select
              id="colorInside"
              v-model="additionalData.colorInside"
              :margin="false"
              placeholder="Bitte wählen"
              name="Farbe der Innenausstattung"
              required
              :options="[
                {label: 'Schwarz', value: 'BLACK'},
                {label: 'Grau', value: 'GREY'},
                {label: 'Beige', value: 'BEIGE'},
                {label: 'Braun', value: 'BROWN'},
                {label: 'Andere', value: 'OTHER_INTERIOR_COLOR'}
              ]"
            />
          </div>
        </div>
      </template>
    </ca-card>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'
export default {
  inject: ['$validator'],
  name: 'Step2',
  components: {
    BasicSelect,
    BasicCheckbox,
    BasicInput,
    CaCard
  },
  props: {
    additionalData: {
      default: () => { return {} },
      type: Object
    }
  }
}
</script>
