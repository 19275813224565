<template>
  <div>
    <ca-card class="mb-3">
      <template #header>
        <h4>Multimedia</h4>
      </template>
      <template #body>
        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-checkbox
              id="tunerRadio"
              v-model="additionalData.tunerRadio"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Tuner / Radio"
            >
              <template #label>
                Multimediaaustattung
              </template>
            </basic-checkbox>
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="radioDab"
              v-model="additionalData.radioDab"
              :margin="false"
              label="Radio DAB"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="CDPlayer"
              v-model="additionalData.CDPlayer"
              :margin="false"
              label="CD-Spieler"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="tv"
              v-model="additionalData.tv"
              :margin="false"
              label="TV"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="navigationSystem"
              v-model="additionalData.navigationSystem"
              :margin="false"
              label="Navigationssystem"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="soundsystem"
              v-model="additionalData.soundsystem"
              :margin="false"
              label="Soundsystem"
            />
          </div>
        </div>
        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-checkbox
              id="touchscreen"
              v-model="additionalData.touchscreen"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Touchscreen"
            >
              <template #label>
                Bedienung und Steuerung
              </template>
            </basic-checkbox>
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="voiceControl"
              v-model="additionalData.voiceControl"
              :margin="false"
              label="Sprachsteuerung"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="multifunctionalSteeringWheel"
              v-model="additionalData.multifunctionalSteeringWheel"
              :margin="false"
              label="Multifunktionslenkrad"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="speakerphone"
              v-model="additionalData.speakerphone"
              :margin="false"
              label="Freisprecheinrichtung"
            />
          </div>
        </div>
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Konnektivität und Schnittstellen</h4>
      </template>
      <template #body>
        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-checkbox
              id="usb"
              v-model="additionalData.usb"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="USB"
            >
              <template #label>
                Konnektivität und Schnittstellen
              </template>
            </basic-checkbox>
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="bluetooth"
              v-model="additionalData.bluetooth"
              :margin="false"
              label="Bluetooth"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="appleCarPlay"
              v-model="additionalData.appleCarPlay"
              :margin="false"
              label="Apple CarPlay"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="androidAuto"
              v-model="additionalData.androidAuto"
              :margin="false"
              label="Android Auto"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="wifi"
              v-model="additionalData.wifi"
              :margin="false"
              label="WLAN / Wifi Hotspot"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="musicStreaming"
              v-model="additionalData.musicStreaming"
              :margin="false"
              label="Musikstreaming intigriert"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-8">
            <basic-checkbox
              id="inductionCharging"
              v-model="additionalData.inductionCharging"
              :margin="false"
              label="Induktionsladen für Smartphones"
            />
          </div>
        </div>
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Instrumentenanzeige</h4>
      </template>
      <template #body>
        <basic-checkbox
          id="boardComputer"
          v-model="additionalData.boardComputer"
          label="Bordcomputer"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        >
          <template #label>
            Instrumentenanzeige
          </template>
        </basic-checkbox>
        <basic-checkbox
          id="headUpDisplay"
          v-model="additionalData.headUpDisplay"
          label="Head-Up Display"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        />
        <basic-checkbox
          id="digitalInstrument"
          v-model="additionalData.digitalInstrument"
          label="Volldigitales Komiinstrument"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        />
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Reifen und Felgen</h4>
      </template>
      <template #body>
        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-checkbox
              id="alloyWheels"
              v-model="additionalData.alloyWheels"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Leichtmetallfelgen"
            >
              <template #label>
                Reifen und Felgen
              </template>
            </basic-checkbox>
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="summerTires"
              v-model="additionalData.summerTires"
              :margin="false"
              label="Sommerreifen"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="winterTires"
              v-model="additionalData.winterTires"
              :margin="false"
              label="Winterreifen"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="allSeasonTries"
              v-model="additionalData.allSeasonTries"
              :margin="false"
              label="Allwetterreifen"
            />
          </div>
        </div>
        <basic-select
          id="roadsideAssistance"
          v-model="additionalData.roadsideAssistance"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Pannenhilfe"
          placeholder="Bitte wählen"
          required
          :options="[
            {label: 'Reserverad', value: 'SPARE_WHEEL'},
            {label: 'Notrad', value: 'EMERGENCY_WHEEL'},
            {label: 'Pannenkit', value: 'REPAIR_KIT'}
          ]"
        />
        <basic-checkbox
          id="tirePressureMonitoringSystem"
          v-model="additionalData.tirePressureMonitoringSystem"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Reifendruckkontrolle"
        />
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Sonderausstattung</h4>
      </template>
      <template #body>
        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-checkbox
              id="winterPackage"
              v-model="additionalData.winterPackage"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Winterpaket"
            >
              <template #label>
                Sonderausstattung
              </template>
            </basic-checkbox>
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="smokingPackage"
              v-model="additionalData.smokingPackage"
              :margin="false"
              label="Raucherpaket"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="sportPackage"
              v-model="additionalData.sportPackage"
              :margin="false"
              label="Sportpaket"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="sportsSuspension"
              v-model="additionalData.sportsSuspension"
              :margin="false"
              label="Sportfahrwerk"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="airSuspension"
              v-model="additionalData.airSuspension"
              :margin="false"
              label="Luftfederung"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="trailerHitch"
              v-model="additionalData.trailerHitch"
              :margin="false"
              label="Anhängerkupplung"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="cargoBarrier"
              v-model="additionalData.cargoBarrier"
              :margin="false"
              label="Gepäckraumabtrennung"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="skiBag"
              v-model="additionalData.skiBag"
              :margin="false"
              label="Skisack"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="sunroof"
              v-model="additionalData.sunroof"
              :margin="false"
              label="Schiebedach"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="panoramicRoof"
              v-model="additionalData.panoramicRoof"
              :margin="false"
              label="Panorama-Dach"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="roofRails"
              v-model="additionalData.roofRails"
              :margin="false"
              label="Dachreling"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="handicappedAccessible"
              v-model="additionalData.handicappedAccessible"
              :margin="false"
              label="Behindertengerecht"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-8">
            <basic-checkbox
              id="taxi"
              v-model="additionalData.taxi"
              :margin="false"
              label="Taxi"
            />
          </div>
        </div>
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>
          Sonstiges
        </h4>
      </template>
      <template #body>
        <div
          v-if="additionalData.images"
          class="form-row"
        >
          <div class="col-12 col-md-4">
            {{ additionalData.images.length }} von {{ maxImages }} Bildern hochgeladen
          </div>
          <div class="col-12 col-md-8">
            <div class="form-row">
              <div
                v-for="(image, index) of additionalData.images"
                :key="`test-${index}`"
                class="col-3 mb-2"
              >
                <div class="img-wrapper">
                  <img
                    :src="image.downloadUrl"
                    alt=""
                  >
                  <div
                    class="delete"
                    @click="removeImage(index)"
                  >
                    <i class="fal fa-lg fa-trash-alt" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <basic-file-upload
          v-model="additionalData.images"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Bilder hochladen"
          :horizontal="true"
          multiple
          accept="image/*"
          label="Bilder hochladen"
          :align-label-center="false"
          hide-file-list
          @uploaded="addImage"
        />
        <basic-textarea
          v-model="additionalData.Note"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          label="Fahzeugbeschreibung"
          rows="3"
          :horizontal="true"
          :align-label-center="false"
        />
      </template>
    </ca-card>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import BasicFileUpload from '@/components/BaseComponents/BasicFileUpload'
import BasicTextarea from '@/components/BaseComponents/BasicTextarea'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'
export default {
  inject: ['$validator'],
  name: 'Step5',
  components: {
    BasicTextarea,
    BasicFileUpload,
    BasicSelect,
    BasicCheckbox,
    CaCard
  },
  props: {
    additionalData: {
      default: () => { return { } },
      type: Object
    }
  },
  data: function () {
    return {
      maxImages: 15
    }
  },
  methods: {
    getRandom () {
      return Math.floor(Math.random() * 100)
    },
    removeImage (index) {
      this.additionalData.imageIds.splice(index, 1)
      this.additionalData.images.splice(index, 1)
    },
    addImage (files) {
      this.additionalData.imageIds.push(...files)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.img-wrapper{
  width: 100%;
  height: 5vw;
  overflow: hidden;
  border-radius: $border-radius;
  &:hover{
    .delete{
      opacity: 0.7;
      cursor: pointer;
    }
    i{
      opacity: 1;
      cursor: pointer;
    }
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .delete{
    transition: opacity 300ms ease;
    background-color: $danger;
    opacity: 0;
    border-radius: $border-radius;
    top: 0;
    bottom: 0;
    left: 5px;
    right: 5px;
    position: absolute;
  }
  i{
    transition: opacity 300ms ease;
    opacity: 0;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
}
</style>
