<template>
  <div>
    <ca-card class="mb-3">
      <template #header>
        <h4>Fahrzeug</h4>
      </template>
      <template #body>
        <basic-input
          id="description"
          v-model="additionalData.description"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Bezeichnung"
          placeholder="Fahrzeugbezeichnung"
          required
        />
        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-select
              id="constructionShape"
              v-model="additionalData.constructionShape"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Bauform / Anzahl Türen"
              name="Bauform"
              placeholder="Bitte wählen"
              required
              :options="[
                {label: 'Andere', value: 'OtherCar'},
                {label: 'Cabrio / Roadster', value: 'Cabrio'},
                {label: 'Kleinwagen', value: 'SmallCar'},
                {label: 'Kombi', value: 'EstateCar'},
                {label: 'Limousine', value: 'Limousine'},
                {label: 'Sportwagen / Coupé', value: 'SportsCar'},
                {label: 'SUV / Geländewagen / Pickup', value: 'OffRoad'},
                {label: 'Van / Minibus', value: 'Van'}
              ]"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-select
              id="numberOfDoors"
              v-model="additionalData.numberOfDoors"
              :margin="false"
              placeholder="Bitte wählen"
              required
              name="Anzahl Türen"
              :options="[
                {label: '2/3', value: 'TWO_OR_THREE'},
                {label: '4/5', value: 'FOUR_OR_FIVE'},
                {label: '6/7', value: 'SIX_OR_SEVEN'}
              ]"
            />
          </div>
        </div>
        <div class="form-row my-3 align-items-center">
          <div class="col-12 col-md-8">
            <basic-select
              id="slidingDoor"
              v-model="additionalData.slidingDoor"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Schiebetür / Anzahl Sitzplätze"
              name="Schiebetür"
              placeholder="Bitte wählen"
              required
              :options="[
                {label: 'keine', value: 'NONE'},
                {label: 'Schiebetür rechts', value: 'SLIDING_DOOR_RIGHT'},
                {label: 'Schiebetür links', value: 'SLIDING_DOOR_LEFT'},
                {label: 'Schiebetür beidseitig', value: 'SLIDING_DOOR_BOTH_SIDED'}
              ]"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-input
              id="numberOfSeats"
              v-model.number="additionalData.numberOfSeats"
              :margin="false"
              required
              type="number"
              name="Anzahl Sitzplätze"
              placeholder="Anzahl Sitzplätze"
            />
          </div>
        </div>
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Antrieb</h4>
      </template>
      <template #body>
        <basic-select
          id="transmission"
          v-model="additionalData.transmission"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Getriebe"
          placeholder="Bitte wählen"
          required
          :options="[
            {label: 'Schaltgetriebe', value: 'MANUAL_GEAR'},
            {label: 'Halbautomatik', value: 'SEMIAUTOMATIC_GEAR'},
            {label: 'Automatik', value: 'AUTOMATIC_GEAR'}
          ]"
        />
        <basic-checkbox
          id="gearshiftPaddles"
          v-model="additionalData.gearshiftPaddles"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Schaltwippen"
        />
        <basic-checkbox
          id="allWhellDrive"
          v-model="additionalData.allWhellDrive"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Allradantrieb"
        />
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Umwelt und Verbrauch</h4>
      </template>
      <template #body>
        <div class="form-row my-3 align-items-center">
          <div class="col-12 col-md-8">
            <basic-select
              id="pollutantClass"
              v-model="additionalData.pollutantClass"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Schadstoffklasse / Umweltplakette"
              name="Schadstoffklasse"
              placeholder="Bitte wählen"
              required
              :options="[
                {label: 'Euro1', value: 'EURO1'},
                {label: 'Euro2', value: 'EURO2'},
                {label: 'Euro3', value: 'EURO3'},
                {label: 'Euro4', value: 'EURO4'},
                {label: 'Euro5', value: 'EURO5'},
                {label: 'Euro6', value: 'EURO6'},
                {label: 'Euro6c', value: 'EURO6C'},
                {label: 'Euro6d-TEMP', value: 'EURO6D_TEMP'},
                {label: 'Euro6d', value: 'EURO6D'}
              ]"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-select
              id="environmentalBadge"
              v-model="additionalData.environmentalBadge"
              :margin="false"
              placeholder="Bitte wählen"
              required
              name="Umweltplakette"
              :options="[
                {label: '4 (Grün)', value: 'EMISSIONSSTICKER_GREEN'},
                {label: '3 (Gelb)', value: 'EMISSIONSSTICKER_YELLOW'},
                {label: '2 (Rot)', value: 'EMISSIONSSTICKER_RED'},
                {label: '1 (Keine)', value: 'EMISSIONSSTICKER_NONE'}
              ]"
            />
          </div>
        </div>
        <basic-checkbox
          id="startStopAutomatic"
          v-model="additionalData.startStopAutomatic"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Start / Stopp-Automatik"
        />
        <basic-checkbox
          id="particleFilter"
          v-model="additionalData.particleFilter"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Partikelfilter"
        />
        <basic-input
          id="fuelConsumptionCombined"
          v-model="additionalData.fuelConsumptionCombined"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Kraftstoffverbrauch (komb.)"
          placeholder="Kraftstoffverbrauch (komb.)"
          required
        >
          <template #append>
            <span class="input-group-text">l/100km</span>
          </template>
        </basic-input>
        <basic-input
          id="fuelConsumptionInTown"
          v-model="additionalData.fuelConsumptionInTown"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Kraftstoffverbrauch (innerorts)"
          placeholder="Kraftstoffverbrauch (innerorts)"
          required
        >
          <template #append>
            <span class="input-group-text">l/100km</span>
          </template>
        </basic-input>
        <basic-input
          id="fuelConsumptionHighway"
          v-model="additionalData.fuelConsumptionHighway"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Kraftstoffverbrauch (außerorts)"
          placeholder="Kraftstoffverbrauch (außerorts)"
          required
        >
          <template #append>
            <span class="input-group-text">l/100km</span>
          </template>
        </basic-input>
        <basic-input
          id="co2EmissionCombined"
          v-model="additionalData.co2EmissionCombined"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="CO2-Emissionen (komb.)"
          placeholder="CO2-Emissionen (komb.)"
          required
        >
          <template #append>
            <span class="input-group-text">g. CO2/km</span>
          </template>
        </basic-input>
      </template>
    </ca-card>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'
export default {
  inject: ['$validator'],
  name: 'Step1',
  components: {
    BasicInput,
    BasicSelect,
    BasicCheckbox,
    CaCard
  },
  props: {
    additionalData: {
      default: () => { return {} },
      type: Object
    }
  }
}
</script>
