<template>
  <div>
    <ca-card class="mb-3">
      <template #header>
        <h4>Insassenschutz</h4>
      </template>
      <template #body>
        <basic-select
          id="airConditioning"
          v-model="additionalData.airConditioning"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Klimatisierung"
          placeholder="Bitte wählen"
          required
          :options="[
            {label: 'Keine Klimaanlage oder -automatik', value: 'NO_CLIMATISATION'},
            {label: 'Klimaanlage', value: 'MANUAL_CLIMATISATION'},
            {label: 'Klimaautomatik', value: 'AUTOMATIC_CLIMATISATION'},
            {label: '2-Zonen-Klimaautomatik', value: 'AUTOMATIC_CLIMATISATION_2_ZONES'},
            {label: '3-Zonen-Klimaautomatik', value: 'AUTOMATIC_CLIMATISATION_3_ZONES'},
            {label: '4-Zonen-Klimaautomatik', value: 'AUTOMATIC_CLIMATISATION_4_ZONES'}
          ]"
        />
        <basic-checkbox
          id="heater"
          v-model="additionalData.heater"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Standheizung"
        />
        <basic-checkbox
          id="heatedWindscreen"
          v-model="additionalData.heatedWindscreen"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Beheizte Frontscheibe"
        />
        <basic-checkbox
          id="heatedSteeringWheel"
          v-model="additionalData.heatedSteeringWheel"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Beheizbares Lenkrad"
        />
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Einparkhilfe</h4>
      </template>
      <template #body>
        <basic-checkbox
          id="SelfSteeringSystems"
          v-model="additionalData.SelfSteeringSystems"
          label="Selbstlenkende Systeme"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        />
        <basic-checkbox
          id="acousticParkingAssistanceFront"
          v-model="additionalData.acousticParkingAssistanceFront"
          label="Akustische Einparkhilfe Vorne"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        >
          <template #label>
            Akustische Einparkhilfe
          </template>
        </basic-checkbox>
        <basic-checkbox
          id="acousticParkingAssistanceBack"
          v-model="additionalData.acousticParkingAssistanceBack"
          label="Akustische Einparkhilfe Hinten"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        />
        <basic-checkbox
          id="camera"
          v-model="additionalData.camera"
          label="Kamera"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        >
          <template #label>
            Visuelle Einparkhilfe
          </template>
        </basic-checkbox>
        <basic-checkbox
          id="surroundCamera"
          v-model="additionalData.surroundCamera"
          label="360°-Kamera"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        />
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Sitze</h4>
      </template>
      <template #body>
        <basic-checkbox
          id="heatedSeatsFront"
          v-model="additionalData.heatedSeatsFront"
          label="Sitzheizung Vorne"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        >
          <template #label>
            Sitzheizung
          </template>
        </basic-checkbox>
        <basic-checkbox
          id="heatedSeatsBack"
          v-model="additionalData.heatedSeatsBack"
          label="Sitzheizung Hinten"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        />
        <basic-checkbox
          id="electricSeatAdjustmentFront"
          v-model="additionalData.electricSeatAdjustmentFront"
          label="Elektrische Sitzeinstellung Vorne"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        >
          <template #label>
            Elektrische Sitzeinstellung
          </template>
        </basic-checkbox>
        <basic-checkbox
          id="electricSeatAdjustmentBack"
          v-model="additionalData.electricSeatAdjustmentBack"
          label="Elektrische Sitzeinstellung Hinten"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        />
        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-checkbox
              id="sportSeats"
              v-model="additionalData.sportSeats"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Sportsitze"
            >
              <template #label>
                Weitere Merkmale
              </template>
            </basic-checkbox>
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="massageSeats"
              v-model="additionalData.massageSeats"
              :margin="false"
              label="Massagesitze"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="armrest"
              v-model="additionalData.armrest"
              :margin="false"
              label="Armlehne"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="seatVentilation"
              v-model="additionalData.seatVentilation"
              :margin="false"
              label="Sitzbelüftung"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="lumbarSupport"
              v-model="additionalData.lumbarSupport"
              :margin="false"
              label="Lordosenstütze"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="reversibleFrontPassengerSeat"
              v-model="additionalData.reversibleFrontPassengerSeat"
              :margin="false"
              label="Umklappbarer Beifahrersitz"
            />
          </div>
        </div>
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Weitere Komfortausstattungen</h4>
      </template>
      <template #body>
        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-checkbox
              id="electricWindows"
              v-model="additionalData.electricWindows"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Elektrische Fensterheber"
            >
              <template #label>
                Weitere Komfortausstattungen
              </template>
            </basic-checkbox>
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="electricSideMirrors"
              v-model="additionalData.electricSideMirrors"
              :margin="false"
              label="Elektrische Seitenspiegel"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="electricTailgate"
              v-model="additionalData.electricTailgate"
              :margin="false"
              label="Elektrische Heckklappe"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="centralLockingSystem"
              v-model="additionalData.centralLockingSystem"
              :margin="false"
              label="Zentralverriegelung"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="keylessCentralLockingSystem"
              v-model="additionalData.keylessCentralLockingSystem"
              :margin="false"
              label="Schlüssellose Zentralverriegelung"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="lightSensor"
              v-model="additionalData.lightSensor"
              :margin="false"
              label="Lichtsensor"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="rainSensor"
              v-model="additionalData.rainSensor"
              :margin="false"
              label="Regensensor"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="powerSteering"
              v-model="additionalData.powerSteering"
              :margin="false"
              label="Servolenkung"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="ambientLight"
              v-model="additionalData.ambientLight"
              :margin="false"
              label="Ambiente-Beleuchtung"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="leatherSteeringWheel"
              v-model="additionalData.leatherSteeringWheel"
              :margin="false"
              label="Lederlenkrad"
            />
          </div>
        </div>
      </template>
    </ca-card>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'
export default {
  inject: ['$validator'],
  name: 'Step4',
  components: {
    BasicSelect,
    BasicCheckbox,
    CaCard
  },
  props: {
    additionalData: {
      default: () => { return {} },
      type: Object
    }
  }
}
</script>
