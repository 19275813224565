<template>
  <div>
    <ca-card class="mb-3">
      <template #header>
        <h4>Assistenzsysteme</h4>
      </template>
      <template #body>
        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-checkbox
              id="abs"
              v-model="additionalData.abs"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Antiblockiersystem (ABS)"
            >
              <template #label>
                Assistenzsysteme
              </template>
            </basic-checkbox>
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="esp"
              v-model="additionalData.esp"
              :margin="false"
              label="Elektronisches Stabilitätsprogramm (ESP)"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="tractionControll"
              v-model="additionalData.tractionControll"
              :margin="false"
              label="Traktionskontrolle (ASR)"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="hillStartAssist"
              v-model="additionalData.hillStartAssist"
              :margin="false"
              label="Bergfahrassistent"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="fatigueWarning"
              v-model="additionalData.fatigueWarning"
              :margin="false"
              label="Müdigkeitswarner"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="laneDepartureAssistant"
              v-model="additionalData.laneDepartureAssistant"
              :margin="false"
              label="Spurhalteassistent"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="bilndSpotAssistant"
              v-model="additionalData.bilndSpotAssistant"
              :margin="false"
              label="Totwinkel-Assistent"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="automaticInteriorMirror"
              v-model="additionalData.automaticInteriorMirror"
              :margin="false"
              label="Innenspiegel autom. abblendend"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="nightVisionAssistant"
              v-model="additionalData.nightVisionAssistant"
              :margin="false"
              label="Nachtsicht-Assistent"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="emergencyBrakeAssistant"
              v-model="additionalData.emergencyBrakeAssistant"
              :margin="false"
              label="Notbremsassistent"
            />
          </div>
        </div>
        <div class="form-row my-3 justify-content-end">
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="emergencyCallSystem"
              v-model="additionalData.emergencyCallSystem"
              :margin="false"
              label="Notrufsystem"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-checkbox
              id="trafficSignRecognition"
              v-model="additionalData.trafficSignRecognition"
              :margin="false"
              label="Verkehrszeichenerkennung"
            />
          </div>
        </div>
        <basic-select
          id="cruiseControl"
          v-model="additionalData.cruiseControl"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Tempomat"
          placeholder="Bitte wählen"
          required
          :options="[
            {label: 'keinen', value: 'NONE'},
            {label: 'Tempomat', value: 'CRUISE_CONTROL'},
            {label: 'Abstandstempomat', value: 'ADAPTIVE_CRUISE_CONTROL'}
          ]"
        />
        <basic-checkbox
          id="speedLimiter"
          v-model="additionalData.speedLimiter"
          label="Geschwindigkeitsbegrenzer"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        />
        <basic-checkbox
          id="distanceWarning"
          v-model="additionalData.distanceWarning"
          label="Abstandswarner"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        />
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Insassenschutz</h4>
      </template>
      <template #body>
        <basic-select
          id="airbags"
          v-model="additionalData.airbags"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Airbags"
          placeholder="Bitte wählen"
          required
          :options="[
            {label: 'Fahrer-Airbag', value: 'DRIVER_AIRBAG'},
            {label: 'Front-Airbags', value: 'FRONT_AIRBAGS'},
            {label: 'Front- und Seiten-Airbags', value: 'FRONT_AND_SIDE_AIRBAGS'},
            {label: 'Front-, Seiten- und weitere Airbags', value: 'FRONT_AND_SIDE_AND_MORE_AIRBAGS'}
          ]"
        />
        <basic-checkbox
          id="isofixCildSeat"
          v-model="additionalData.isofixCildSeat"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Isofix (Kindersitzbefestigung)"
        />
        <basic-checkbox
          id="isofixPassengerSeat"
          v-model="additionalData.isofixPassengerSeat"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Isofix Beifahrersitz"
        />
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Licht und Sicht</h4>
      </template>
      <template #body>
        <basic-select
          id="typeOfHeadlights"
          v-model="additionalData.typeOfHeadlights"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Scheinwerfer"
          placeholder="Bitte wählen"
          required
          :options="[
            {label: 'Xenonscheinwerfer', value: 'XENON_HEADLIGHTS'},
            {label: 'Bi-Xenon Scheinwerfer', value: 'BI_XENON_HEADLIGHTS'},
            {label: 'LED-Scheinwerfer', value: 'LED_HEADLIGHTS'},
            {label: 'Laserlicht', value: 'LASER_HEADLIGHTS'}
          ]"
        />
        <basic-checkbox
          id="headlightsWasher"
          v-model="additionalData.headlightsWasher"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Scheinwerferreinigung"
        />
        <basic-checkbox
          id="GlareFreeHighBeam"
          v-model="additionalData.GlareFreeHighBeam"
          :horizontal="true"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          label="Blendfreies Fernlicht"
        >
          <template #label>
            Fernlicht
          </template>
        </basic-checkbox>
        <basic-checkbox
          id="highBeamAssistant"
          v-model="additionalData.highBeamAssistant"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Fernlichtassistent"
        />
        <div class="form-row my-3 align-items-center">
          <div class="col-12 col-md-8">
            <basic-select
              id="daytimeLights"
              v-model="additionalData.daytimeLights"
              :margin="false"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Tagfahrlicht / Kurvenlicht"
              name="Tagfahrlicht"
              placeholder="Bitte wählen"
              required
              :options="[
                {label: 'Tagfahrlicht', value: 'DAYTIME_RUNNING_LIGHTS'},
                {label: 'LED-Tagfahrlicht', value: 'LED_RUNNING_LIGHTS'}
              ]"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-select
              id="bendLighting"
              v-model="additionalData.bendLighting"
              :margin="false"
              placeholder="Bitte wählen"
              name="Kurvenlicht"
              required
              :options="[
                {label: 'Kurvenlicht', value: 'BENDING_LIGHTS'},
                {label: 'Adaptives Kurvenlicht', value: 'ADAPTIVE_BENDING_LIGHTS'}
              ]"
            />
          </div>
        </div>
        <basic-checkbox
          id="fogLights"
          v-model="additionalData.fogLights"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Nebelscheinwerfer"
        />
      </template>
    </ca-card>
    <ca-card class="mb-3">
      <template #header>
        <h4>Diebstahlschutz</h4>
      </template>
      <template #body>
        <basic-checkbox
          id="alarmSystem"
          v-model="additionalData.alarmSystem"
          label="Alarmanlage"
        />
        <basic-checkbox
          id="electricImmobilizer"
          v-model="additionalData.electricImmobilizer"
          label="Elektrische Wegfahrsperre"
        />
      </template>
    </ca-card>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'
export default {
  inject: ['$validator'],
  name: 'Step3',
  components: {
    BasicSelect,
    BasicCheckbox,
    CaCard
  },
  props: {
    additionalData: {
      default: () => { return {} },
      type: Object
    }
  }
}
</script>
